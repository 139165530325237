import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchVocLastDataRefresh, setPageName } from "../../../actions";
import {
  fetchExitSurveyDeviceDistribution,
  fetchExitSurveyFeedback,
  fetchExitSurveyNonCompletionReasons,
  fetchExitSurveyObjectiveCompletion,
  fetchExitSurveyObjectiveCompletionByMarket,
  fetchExitSurveyObjectiveCompletionSelectedMarkets,
  fetchExitSurveyObjectiveCompletionTrend,
  fetchMarketOverview,
  fetchRatingPerTouchpointCompletionRate,
  fetchRatingPerTouchPointEditorial,
  fetchRatingPerTouchpointElectrification,
  fetchRatingPerTouchpointMarketBreakdown,
  fetchRatingPerTouchpointMediaCampaigns,
  fetchRatingPerTouchpointOtherTouchpoints,
  fetchRatingPerTouchpointOwnership,
  fetchRatingPerTouchpointVlpFocus,
  fetchStarRatingAverageRating,
  fetchStarRatingAverageRatingByMarket,
  fetchStarRatingAverageRatingSelectedMarkets,
  fetchStarRatingAverageRatingTrend,
  fetchStarRatingDeviceDistribution,
  fetchStarRatingFeedback,
  fetchStarRatingScoreDistribution,
  fetchTopicClassificationDissatisfaction,
  fetchTopicClassificationSatisfaction,
  setCsvExperienceRating,
} from "../../../actions/vocActions";
import "../../../assets/styles/pages/vocWebsite.scss";
import Collapsible from "../../../components/Collapsible/Collapsible";
import VocWebsiteFilters from "../../../components/Filters/Voc/VocWebsiteFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { ToggleSwitch } from "../../../components/Toogle";
import { VOC_BTN_FILTERS } from "../../../constants/vocConstants";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import ExitSurvey from "./components/ExitSurvey";
import MarketOverviewTable from "./components/MarketOverviewTable";
import RatingPerTouchpoint from "./components/RatingPerTouchpoint";
import StarRatingOverview from "./components/StarRatingOverview";
import TopCategories from "./components/TopCategories";
import VerbatimTable from "./components/VerbatimTable";
import { DashboardBannerContext } from "../../../context";
import { VOC_DATE_FILTER_OPTIONS } from "../../../constants";
import { parse } from "date-fns";
import { isCustomDate } from "../../../utils/dateFunctions";

const Website = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { history } = props;

  const { setShowBanner, setBannerText } = useContext(DashboardBannerContext);

  const {
    star_rating_market: starRatingMarket,
    objective_completion_market: objectiveCompletionMarket,
    date_range: dateRangeParam,
  } = useSelector((state: RootStateOrAny) => state.parameters);
  const marketOverview = useSelector((state: RootStateOrAny) => state.voc.market_overview);

  const { topic_categories: dissatisfactionTopicCategories, data: dissatisfactionChartData } = useSelector(
    (state: RootStateOrAny) => state.voc.topic_classification_dissatisfaction
  );
  const dissatisfactionTopicVerbatim = useSelector((state: RootStateOrAny) => state.voc.topic_verbatim_dissatisfaction);
  const {
    topic_classification_dissatisfaction: dissatisfactionTopicClassificationLoading,
    topic_verbatim_dissatisfaction: dissatisfactionVerbatimLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);

  const { topic_categories: satisfactionTopicCategories, data: satisfactionChartData } = useSelector(
    (state: RootStateOrAny) => state.voc.topic_classification_satisfaction
  );
  const satisfactionTopicVerbatim = useSelector((state: RootStateOrAny) => state.voc.topic_verbatim_satisfaction);
  const {
    topic_classification_satisfaction: satisfactionTopicClassificationLoading,
    topic_verbatim_satisfaction: satisfactionVerbatimLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);

  const [showMarketOverviewRatings, setShowMarketOverviewRatings] = useState(true);

  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.voc_last_data_refresh);

  const [verbatimToggleValue, setVerbatimToggleValue] = useState(true);

  const europeData = useMemo(() => {
    const regionData = marketOverview.filter((item: any) => item.region == "NAE");
    const markets = regionData.map((item: any) => item.market);
    const golive = regionData.map((item: any) => (item.date ? moment(item.date).format("MMMM DD, YYYY") : item.To_be_confirmed));
    const lastThreeMonths = regionData.map((item: any) =>
      showMarketOverviewRatings ? item.selected_period_rate : item.selected_period_volume
    );
    const previousQuarter = regionData.map((item: any) =>
      showMarketOverviewRatings ? item.previous_period_rate : item.previous_period_volume
    );
    const sinceSurveyLaunch = regionData.map((item: any) =>
      showMarketOverviewRatings ? item.since_launch_rate : item.since_launch_volume
    );
    return {
      region: "EUROPE",
      marketRow: markets,
      goLiveRow: golive,
      lastThreeMonthsRow: lastThreeMonths,
      previousQuarterRow: previousQuarter,
      sinceSurveyLaunchRow: sinceSurveyLaunch,
    };
  }, [marketOverview, showMarketOverviewRatings]);

  const amioData = useMemo(() => {
    const regionData = marketOverview.filter((item: any) => item.region == "AMIEO");
    const markets = regionData.map((item: any) => item.market);
    const golive = regionData.map((item: any) => (item.date ? moment(item.date).format("MMMM DD, YYYY") : item.To_be_confirmed));
    const lastThreeMonths = regionData.map((item: any) =>
      showMarketOverviewRatings ? item.selected_period_rate : item.selected_period_volume
    );
    const previousQuarter = regionData.map((item: any) =>
      showMarketOverviewRatings ? item.previous_period_rate : item.previous_period_volume
    );
    const sinceSurveyLaunch = regionData.map((item: any) =>
      showMarketOverviewRatings ? item.since_launch_rate : item.since_launch_volume
    );
    return {
      region: "AMIO",
      marketRow: markets,
      goLiveRow: golive,
      lastThreeMonthsRow: lastThreeMonths,
      previousQuarterRow: previousQuarter,
      sinceSurveyLaunchRow: sinceSurveyLaunch,
    };
  }, [marketOverview, showMarketOverviewRatings]);

  useEffect(() => {
    dispatch(setPageName("VOC (Voice Of Customer)"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMarketOverview());
    dispatch(fetchStarRatingAverageRating());
    dispatch(fetchStarRatingAverageRatingTrend());
    dispatch(fetchStarRatingFeedback());
    dispatch(fetchStarRatingScoreDistribution());
    dispatch(fetchStarRatingDeviceDistribution());
    dispatch(fetchExitSurveyObjectiveCompletion());
    dispatch(fetchExitSurveyFeedback());
    dispatch(fetchExitSurveyObjectiveCompletionTrend());
    dispatch(fetchExitSurveyNonCompletionReasons());
    dispatch(fetchExitSurveyDeviceDistribution());
    dispatch(fetchRatingPerTouchpointMarketBreakdown());
    dispatch(fetchRatingPerTouchpointVlpFocus());
    dispatch(fetchRatingPerTouchpointElectrification());
    dispatch(fetchRatingPerTouchpointOtherTouchpoints());
    dispatch(fetchRatingPerTouchpointMediaCampaigns());
    dispatch(fetchRatingPerTouchpointCompletionRate());
    dispatch(fetchRatingPerTouchpointOwnership());
    dispatch(fetchRatingPerTouchPointEditorial());
    dispatch(fetchTopicClassificationDissatisfaction());
    dispatch(fetchTopicClassificationSatisfaction());
    dispatch(fetchVocLastDataRefresh());
  }, [history.location.search, dispatch]);

  useEffect(() => {
    Promise.all([
      dispatch(fetchStarRatingAverageRatingSelectedMarkets(starRatingMarket)),
      dispatch(fetchStarRatingAverageRatingByMarket(starRatingMarket)),
    ]);
  }, [history.location.search, dispatch, starRatingMarket]);

  useEffect(() => {
    Promise.all([
      dispatch(fetchExitSurveyObjectiveCompletionByMarket(objectiveCompletionMarket)),
      dispatch(fetchExitSurveyObjectiveCompletionSelectedMarkets(objectiveCompletionMarket)),
    ]);
  }, [history.location.search, dispatch, objectiveCompletionMarket]);

  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "VOC", page: "VOC" });
  }, [location.pathname]);

  useEffect(() => {
    if (verbatimToggleValue) {
      dispatch(setCsvExperienceRating([1, 2, 3]));
    } else {
      dispatch(setCsvExperienceRating([4, 5]));
    }
  }, [verbatimToggleValue]);

  useEffect(() => {
    if (dateRangeParam && !VOC_DATE_FILTER_OPTIONS.includes(dateRangeParam) && isCustomDate(dateRangeParam)) {
      const startDate = parse(dateRangeParam.split("-")[0].trim(), "dd/MM/yyyy", new Date());
      const endDate = parse(dateRangeParam.split("-")[1].trim(), "dd/MM/yyyy", new Date());
      if (
        (startDate.getMonth() > 2 &&
          startDate.getFullYear() < endDate.getFullYear() &&
          endDate > new Date(startDate.getFullYear() + 1, 2, 31)) ||
        (startDate.getMonth() <= 2 &&
          startDate.getFullYear() == endDate.getFullYear() &&
          endDate > new Date(startDate.getFullYear(), 2, 31))
      ) {
        setShowBanner(true);
        setBannerText(
          "Be aware that some touchpoints are aggregated in both fiscal years under different categories, and therefore counted in the Overall rating. Please select only 1 fiscal year to see the accurate aggregation of ratings."
        );
      } else {
        setShowBanner(false);
        setBannerText("");
      }
    }

    return () => {
      setShowBanner(false);
      setBannerText("");
    };
  }, [dateRangeParam]);
  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<VocWebsiteFilters filterBtns={VOC_BTN_FILTERS} />}
        dataDocumentation="voc"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="voc" />}
      >
        <div className="vocPage">
          <Collapsible
            title="1. Markets official rating"
            show={true}
            tooltipText="The below data is fixed and will not change according to the filters"
          >
            <div className="market-overview-toggle-container">
              <ToggleSwitch
                activeToggleLabel={"Rating"}
                inactiveToggleLabel={"Volume"}
                active={showMarketOverviewRatings}
                handleToggleClick={() => setShowMarketOverviewRatings((prevState) => !prevState)}
              />
            </div>

            <div className="market-overview-container">
              <MarketOverviewTable isLoading={false} data={europeData} isRating={showMarketOverviewRatings} />
              <MarketOverviewTable isLoading={false} data={amioData} isRating={showMarketOverviewRatings} />
            </div>
          </Collapsible>
          <Collapsible title="2. Overview at a glance" show={true}>
            <StarRatingOverview />
          </Collapsible>
          <Collapsible title="3. Rating per touchpoint" show={true}>
            <RatingPerTouchpoint />
          </Collapsible>
          <Collapsible
            title={"4. Top 10 categories and verbatims"}
            tooltipText="WIP: we keep on improving the topic classification"
            show={true}
            toggle={
              <ToggleSwitch
                activeToggleLabel={"Ratings from 1 to 3"}
                inactiveToggleLabel={"Ratings from 4 to 5"}
                active={verbatimToggleValue}
                handleToggleClick={() => {
                  setVerbatimToggleValue((prevState) => !prevState);
                }}
                toggleClassName={"filter_toggle"}
              />
            }
          >
            <TopCategories
              categories={verbatimToggleValue ? dissatisfactionTopicCategories : satisfactionTopicCategories}
              chartData={verbatimToggleValue ? dissatisfactionChartData : satisfactionChartData}
              topicClassificationLoading={
                verbatimToggleValue ? dissatisfactionTopicClassificationLoading : satisfactionTopicClassificationLoading
              }
            />
          </Collapsible>
          <Collapsible title="5. Feedback Comments" show={true}>
            <VerbatimTable />
          </Collapsible>
          <Collapsible title="6. Exit survey" show={false}>
            <ExitSurvey />
          </Collapsible>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default Website;
