import React from "react";
import { CcsVitalSignsData, CPOData, DigitalData, ModelData, MtmData, ProductMarketingOptimization, SEOData, UsedCarsData } from "../docs";
import { BrandPerformanceData } from "../docs/BrandPerformanceData";
import EcommerceData from "../docs/EcommerceData";
import ExecutiveSummaryData from "../docs/ExecutiveSummaryData";
import JourneyExcellenceData from "../docs/JourneyExcellenceData";
import { MidasData } from "../docs/MidasData";
import MsrpData from "../docs/MsrpData";
import ProductPerformance from "../docs/ProductPerformance";
import { PurchaseFunnelData } from "../docs/PurchaseFunnelData";
import UseOwnershipData from "../docs/useOwnership/Overview";
import VocData from "../docs/VocData";

interface Props {
  value: string;
}

export const SwitchDocumentation = (props: Props): JSX.Element => {
  switch (props.value) {
    case "digital_data":
      return <DigitalData />;

    case "model_data":
      return <ModelData />;

    case "seo_data":
      return <SEOData />;

    case "ccs_data":
      return <CcsVitalSignsData />;

    case "mtm_data":
      return <MtmData />;

    case "purchase_funnel":
      return <PurchaseFunnelData />;
    case "cpo_data":
      return <CPOData />;

    case "product_marketing_optimization":
      return <ProductMarketingOptimization />;

    case "midas":
      return <MidasData />;

    case "product_performance_dashboard":
      return <ProductPerformance />;

    case "voc":
      return <VocData />;

    case "cpo_operational":
      return <UsedCarsData />;

    case "ecommerce":
      return <EcommerceData />;

    case "journey_excellence":
      return <JourneyExcellenceData />;

    case "msrp":
      return <MsrpData />;

    case "use_ownership/overview":
      return <UseOwnershipData page="Overview" />;

    case "use_ownership/online_account_and_engagement":
      return <UseOwnershipData page="Online Account and Engagement" />;

    case "use_ownership/service_lead":
      return <UseOwnershipData page="Service Lead" />;

    case "use_ownership/self_service":
      return <UseOwnershipData page="Self Service" />;

    case "brand_performance":
      return <BrandPerformanceData />;

    case "executive_summary":
      return <ExecutiveSummaryData />;

    default:
      return <DigitalData />;
  }
};
