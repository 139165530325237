import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Tooltip } from "react-tippy";
import warning from "../../assets/images/PageContent/warning.svg";
import { PAGE_NAME_URL_MAPPING } from "../../constants";
import { DocumentationContext } from "../../context";
import { useVisible } from "../../hooks";
import { getMissingDates } from "../../utils/dateFunctions";

const pathExcludingDataLink: Array<string> = [];

interface Props extends RouteComponentProps {
  lastDataRefresh: string;
  dataDocumentation: string;
  showLastDataRefreshAlert?: boolean;
  onHandlePDFDownload: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleSaveHomePageClick: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleCSVDownload: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleEuropeActivationCSVDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleOCECSVDownload?: (csv_type: string) => void;
  onHandleCPOCSVDownload?: (csv_type: string) => void;
  onHandlePMOCSVDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleMarketingCalendarCSVDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleDFPCSVDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleMidasCsvDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleProductPerformanceCSVDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleVOCWebsiteCSVDownload?: (csv_type: string) => void;
  onHandleVOCExcelDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleQuickVocSalesCSVDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleQuickVocAfterSalesCSVDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleQuickVocCecCSVDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleCustomerJourneyVocCSVDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleJourneyExcellenceCSVDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleCpoOperationalCSVDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleMSRPCSVDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleBrandPerformanceCSVDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleExecutiveSummaryCSVDownload?: (isChannelMix?: boolean) => void;
  onHandleUseOwnershipCSVDownload?: (evt: React.MouseEvent<HTMLElement>) => void;
  onHandleDISCsvDownload?: (csv_type: string) => void;
  isAriya?: boolean;
  hideDownloadPdf?: boolean;
  canadaUpdate?: string;
  isOCE?: boolean;
}

// Pdf and Last data refresh
const MenuOptions = withRouter((props: Props) => {
  const {
    onHandlePDFDownload,
    onHandleCSVDownload,
    onHandleCPOCSVDownload,
    onHandleEuropeActivationCSVDownload,
    onHandleOCECSVDownload,
    onHandleDFPCSVDownload,
    onHandlePMOCSVDownload,
    onHandleMarketingCalendarCSVDownload,
    onHandleMidasCsvDownload,
    onHandleVOCWebsiteCSVDownload,
    onHandleVOCExcelDownload,
    onHandleQuickVocSalesCSVDownload,
    onHandleQuickVocAfterSalesCSVDownload,
    onHandleQuickVocCecCSVDownload,
    onHandleCustomerJourneyVocCSVDownload,
    onHandleCpoOperationalCSVDownload,
    onHandleSaveHomePageClick,
    onHandleProductPerformanceCSVDownload,
    onHandleJourneyExcellenceCSVDownload,
    onHandleMSRPCSVDownload,
    onHandleBrandPerformanceCSVDownload,
    onHandleExecutiveSummaryCSVDownload,
    onHandleUseOwnershipCSVDownload,
    onHandleDISCsvDownload,
    lastDataRefresh,
    dataDocumentation,
    showLastDataRefreshAlert,
    isAriya,
    hideDownloadPdf,
    location,
    canadaUpdate,
  } = props;
  const pageName = useSelector((state: RootStateOrAny) => state.page.pageName);
  const data_refresh = useSelector((state: RootStateOrAny) => state.data_refresh);
  const { market: marketParamValue } = useSelector((state: RootStateOrAny) => state.parameters);
  const { setIsVisible, setDocumentation } = useContext(DocumentationContext);
  const [dataRefreshValue, setDataRefreshValue] = useState("");
  const [showAlertIcon, setShowAlertIcon] = useState(false);
  const [lastDataRefreshAlert, setLastDataRefreshAlert] = useState("");
  const { wrapperRef, isVisible: isAlertVisible, setIsVisible: setIsAlertVisible } = useVisible(false);
  const [daysToSubtract, setDaysToSubtract] = useState<number>(1);

  const [path] = useState(window.location.href);
  const [showLastDataRefreshTooltip, setShowLastDataRefreshTooltip] = useState(false);

  useEffect(() => {
    pageName && Object.keys(PAGE_NAME_URL_MAPPING).includes(pageName)
      ? setDataRefreshValue(data_refresh[PAGE_NAME_URL_MAPPING[pageName]])
      : setDataRefreshValue(data_refresh["digital_performance"]);

    pageName.includes("SEO") ? setDaysToSubtract(5) : setDaysToSubtract(1);
  }, [pageName, data_refresh]);

  useEffect(() => {
    if (dataRefreshValue) {
      const { isLastDataRefreshAccurate, alertMessage } = getMissingDates(dataRefreshValue, daysToSubtract);
      setShowAlertIcon(!isLastDataRefreshAccurate);
      setLastDataRefreshAlert(alertMessage);
    }
  }, [dataRefreshValue]);

  useEffect(() => {
    if (path.includes("voc")) {
      setShowLastDataRefreshTooltip(true);
    } else {
      setShowLastDataRefreshTooltip(false);
    }
  }, [path]);

  const showCsvButton = () => {
    if (location.pathname.includes("europe_activation")) {
      return (
        <span id="download_csv" onClick={onHandleEuropeActivationCSVDownload} data-pdf-name={"Europe Activation"}>
          Download data
        </span>
      );
    }
  };

  const showOCECsvDownloadButton = () => {
    if (location.pathname.includes("digital_performance") && !location.pathname.includes("cpo")) {
      return (
        <div id="oce_download_csv">
          <span>Download data as CSV &#8594;</span>
          {showOCECsvDownloadOptions()}
        </div>
      );
    }
  };

  const showCPOCsvDownloadButton = () => {
    if (location.pathname.includes("cpo_digital_performance")) {
      return (
        <div id="oce_download_csv">
          <span>Download data as CSV &#8594;</span>
          {showCPOCsvDownloadOptions()}
        </div>
      );
    }
  };

  const showCpoOperationalCsvDownloadButton = () => {
    if (location.pathname.includes("cpo_operational")) {
      return (
        <div id="cpo_operational_download_csv" onClick={onHandleCpoOperationalCSVDownload}>
          <span>Download data as CSV &#8594;</span>
        </div>
      );
    }
  };

  const showOCECsvDownloadOptions = () => (
    <div id="oce_csv_download_options" className="page_content_tooltip">
      <span
        id="oce_overall_download_csv"
        onClick={() => onHandleOCECSVDownload && onHandleOCECSVDownload("overall")}
        data-pdf-name={"Online CE performance overall"}
      >
        Overall
      </span>
      <span
        id="oce_model_breakdown_dolwnload_csv"
        onClick={() => onHandleOCECSVDownload && onHandleOCECSVDownload("model")}
        data-pdf-name={"Online CE performance model breakdown"}
      >
        Model breakdown
      </span>
      <span
        id="oce_channel_breakdown_dolwnload_csv"
        onClick={() => onHandleOCECSVDownload && onHandleOCECSVDownload("channel")}
        data-pdf-name={"Online CE performance channel breakdown"}
      >
        Channel breakdown
      </span>
    </div>
  );

  const showCPOCsvDownloadOptions = () => (
    <div id="oce_csv_download_options" className="page_content_tooltip">
      <span
        id="oce_overall_download_csv"
        onClick={() => onHandleCPOCSVDownload && onHandleCPOCSVDownload("overall")}
        data-pdf-name={"Online CE performance overall"}
      >
        Overall
      </span>
      <span
        id="oce_model_breakdown_dolwnload_csv"
        onClick={() => onHandleCPOCSVDownload && onHandleCPOCSVDownload("model")}
        data-pdf-name={"Online CE performance model breakdown"}
      >
        Model breakdown
      </span>
    </div>
  );

  const showVOCWebsiteCsvDownloadOptions = () => (
    <div id="voc_csv_download_options" className="page_content_tooltip">
      <span
        id="voc_overall_download_csv"
        onClick={() => onHandleVOCWebsiteCSVDownload && onHandleVOCWebsiteCSVDownload("overall")}
        data-pdf-name={"VOC overall"}
      >
        Overall
      </span>
      <span
        id="voc_star_rating_download_csv"
        onClick={() => onHandleVOCWebsiteCSVDownload && onHandleVOCWebsiteCSVDownload("star-rating-overview")}
        data-pdf-name={"VOC star rating overview"}
      >
        Overview at a glance
      </span>
      <span
        id="voc_rtp_download_csv"
        onClick={() => onHandleVOCWebsiteCSVDownload && onHandleVOCWebsiteCSVDownload("rpt")}
        data-pdf-name={"VOC rtp"}
      >
        Rating per touchpoint
      </span>
      <span
        id="voc_top_categories_download_csv"
        onClick={() => onHandleVOCWebsiteCSVDownload && onHandleVOCWebsiteCSVDownload("top-ten-categories")}
        data-pdf-name={"VOC top 10 categories and verbatims"}
      >
        Top 10 categories and verbatims
      </span>
      <span
        id="voc_feedback_comments_download_csv"
        onClick={() => onHandleVOCWebsiteCSVDownload && onHandleVOCWebsiteCSVDownload("feedback-comments")}
        data-pdf-name={"VOC feedback comments"}
      >
        Feedback Comments
      </span>
      <span
        id="voc_exit_survey_download_csv"
        onClick={() => onHandleVOCWebsiteCSVDownload && onHandleVOCWebsiteCSVDownload("exit-survey")}
        data-pdf-name={"VOC exit survey"}
      >
        Exit survey
      </span>
    </div>
  );

  const showQuickVocSalesCsvDownloadButton = () => {
    if (location.pathname.includes("voc/quick_voc_sales")) {
      return (
        <span id="quick_voc_sales_download_csv" onClick={onHandleQuickVocSalesCSVDownload}>
          Download data as CSV
        </span>
      );
    }
  };

  const showQuickVocAfterSalesCsvDownloadButton = () => {
    if (location.pathname.includes("voc/quick_voc_after_sales")) {
      return (
        <span id="quick_voc_after_sales_download_csv" onClick={onHandleQuickVocAfterSalesCSVDownload}>
          Download data as CSV
        </span>
      );
    }
  };

  const showQuickVocCecCsvDownloadButton = () => {
    if (location.pathname.includes("voc/quick_voc_cec")) {
      return (
        <span id="quick_voc_cec_download_csv" onClick={onHandleQuickVocCecCSVDownload}>
          Download data as CSV
        </span>
      );
    }
  };

  const showCustomerJourneyVocCsvDownloadButton = () => {
    if (location.pathname.includes("voc/voc_360")) {
      return (
        <span id="voc_360_download_csv" onClick={onHandleCustomerJourneyVocCSVDownload}>
          Download data as CSV
        </span>
      );
    }
  };

  const showMidasCsvDownloadButton = () => {
    if (location.pathname.includes("midas")) {
      return (
        <span id="fmi_run_rate_download_csv" onClick={onHandleMidasCsvDownload}>
          Download data as CSV
        </span>
      );
    }
  };

  const showPMOCsvDownloadButton = () => {
    if (location.pathname.includes("product_marketing_optimization")) {
      return (
        <span id="pmo_download_csv" onClick={location.pathname.includes("dfp_dashboard") ? onHandleDFPCSVDownload : onHandlePMOCSVDownload}>
          Download data as CSV
        </span>
      );
    }
  };

  const showMarketingCalendarDownloadButton = () => {
    if (location.pathname.includes("product_marketing_optimization")) {
      return (
        <span id="pmo_download_csv" onClick={onHandleMarketingCalendarCSVDownload}>
          Download marketing calendar as CSV
        </span>
      );
    }
  };

  const showProductPerformanceCsvDownloadButton = () => {
    if (location.pathname.split("/")?.at(1) == "product_performance") {
      return (
        <span id="product_performance_download_csv" onClick={onHandleProductPerformanceCSVDownload}>
          Download data as CSV
        </span>
      );
    }
  };

  const showJourneyExcellenceCsvDownloadButton = () => {
    if (location.pathname.includes("journey_excellence")) {
      return (
        <span id="journey_excellence_download_csv" onClick={onHandleJourneyExcellenceCSVDownload}>
          Download data as CSV
        </span>
      );
    }
  };

  const showMSRPCsvDownloadButton = () => {
    if (location.pathname.includes("msrp")) {
      return (
        <span id="msrp_download_csv" onClick={onHandleMSRPCSVDownload}>
          Download data as CSV
        </span>
      );
    }
  };

  const showVOCCsvDownloadButton = () => {
    if (location.pathname.includes("voc/website")) {
      return (
        <div id="voc_download_csv">
          <span>Download data as CSV &#8594;</span>
          {showVOCWebsiteCsvDownloadOptions()}
        </div>
      );
    }
  };

  const showBrandPerforamanceCSVDownloadButton = () => {
    if (location.pathname.includes("brand_performance")) {
      return (
        <div id="brand_perforamance_download_csv" onClick={onHandleBrandPerformanceCSVDownload}>
          <span>Download data as CSV</span>
        </div>
      );
    }
  };

  const showUseOwnershipCSVDownloadButton = () => {
    if (location.pathname.includes("use_ownership")) {
      return (
        <div id="use_ownership_download_csv" onClick={onHandleUseOwnershipCSVDownload}>
          <span>Download data as CSV</span>
        </div>
      );
    }
  };

  const showVOCExcelDownloadButton = () => {
    if (location.pathname.includes("voc/website")) {
      return (
        <span id="voc_download_xlsx" onClick={onHandleVOCExcelDownload}>
          Download data as XLSX
        </span>
      );
    }
  };

  const showExecutiveSummaryCSVDownloadButton = () => {
    const pathname = location.pathname;
    if (pathname.includes("executive_summary")) {
      return (
        <div
          id="executive_summary_download_csv"
          onClick={() => (onHandleExecutiveSummaryCSVDownload && !pathname.includes("dis") ? onHandleExecutiveSummaryCSVDownload() : false)}
        >
          <span>Download data as CSV &#8594;</span>
          {pathname.includes("dis") && (
            <div id="executive_summary_dis_csv_download_options" className="page_content_tooltip">
              <span
                id="executive_summary_dis_overall_download_csv"
                onClick={() => onHandleExecutiveSummaryCSVDownload && onHandleExecutiveSummaryCSVDownload()}
                data-pdf-name={"Overall"}
              >
                Overall
              </span>
              <span
                id="executive_summary_dis_channel_mix_download_csv"
                onClick={() => onHandleExecutiveSummaryCSVDownload && onHandleExecutiveSummaryCSVDownload(true)}
                data-pdf-name={"Channel Mix"}
              >
                Channel Mix
              </span>
            </div>
          )}
        </div>
      );
    }
  };

  const showDISCSVDownloadButton = () => {
    if (location.pathname.includes("purchase_funnel_report")) {
      return (
        <div id="dis_download_csv">
          <span>Download data as CSV &#8594;</span>
          {showDISCsvDownloadOptions()}
        </div>
      );
    }
  };

  const showDISCsvDownloadOptions = () => {
    const showCarModel =
      marketParamValue && marketParamValue != "All" && marketParamValue != "All countries" && marketParamValue.split(",").length == 1;
    return (
      <div id="dis_csv_download_options" className="page_content_tooltip">
        <span
          id="dis_overall_download_csv"
          onClick={() => onHandleDISCsvDownload && onHandleDISCsvDownload("overall")}
          data-pdf-name={"DIS overall"}
        >
          Overall
        </span>
        {showCarModel && (
          <span
            id="dis__car_model_dolwnload_csv"
            onClick={() => onHandleDISCsvDownload && onHandleDISCsvDownload("car model")}
            data-pdf-name={"DIS car model"}
          >
            Car model
          </span>
        )}
      </div>
    );
  };

  return (
    <div className={`pdf_last_updated ${isAriya ? "ariya" : ""}`}>
      {showLastDataRefreshAlert && (
        <div
          className={`last_refreshed_alert ${showAlertIcon ? "show_alert" : ""}`}
          ref={wrapperRef}
          onClick={() => setIsAlertVisible(!isAlertVisible)}
        >
          <img className="alert_icon" src={warning} alt="Last data refresh icon" />
          <div className={`page_content_tooltip alert_tooltip ${isAlertVisible ? "show_alert_tooltip" : ""}`}>
            <p dangerouslySetInnerHTML={{ __html: lastDataRefreshAlert }} />
          </div>
        </div>
      )}

      {showLastDataRefreshTooltip && (
        <>
          <Tooltip
            position="left"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            distance={50}
            html={
              <div>
                <p>CSV download might contain native language verbatims in the wrong format.</p>
                <p>Excel format download can only be made 1 market at a time, otherwise the data sample is too big and will time out.</p>
              </div>
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </>
      )}

      <div className="last_updated" id="funnel_updated">
        Last data refresh:
        <span className="refreshDate">{` ${lastDataRefresh}`}</span>
        {canadaUpdate && (
          <span>
            <br />
            Last Canada update: <span className="refreshDate">{canadaUpdate.length > 1 ? canadaUpdate : ""}</span>
          </span>
        )}
        <br />
        {location.pathname?.includes("midas") ? <p>FX Rate: Latest BP</p> : null}
      </div>

      <div className="pdf_menu hide_on_mobile">
        <div className={"blue_pdf"} />
        <div className="page_content_tooltip pdf_menu_tooltip">
          {hideDownloadPdf ? null : (
            <span id="download_pdf" onClick={onHandlePDFDownload} data-pdf-name={"Online CE performance"}>
              Download view as PDF
            </span>
          )}

          {showCsvButton()}

          {showOCECsvDownloadButton()}
          {showPMOCsvDownloadButton()}
          {showMarketingCalendarDownloadButton()}
          {showMidasCsvDownloadButton()}
          {showProductPerformanceCsvDownloadButton()}
          {/* {showOCECsvDownloadOptions()} */}
          {showVOCCsvDownloadButton()}
          {showVOCExcelDownloadButton()}
          {showJourneyExcellenceCsvDownloadButton()}
          {showCpoOperationalCsvDownloadButton()}
          {showMSRPCsvDownloadButton()}
          {showBrandPerforamanceCSVDownloadButton()}
          {showExecutiveSummaryCSVDownloadButton()}
          {showUseOwnershipCSVDownloadButton()}
          {showCPOCsvDownloadButton()}
          {showDISCSVDownloadButton()}
          {showQuickVocSalesCsvDownloadButton()}
          {showQuickVocAfterSalesCsvDownloadButton()}
          {showQuickVocCecCsvDownloadButton()}
          {/* {showVoc360CsvDownloadButton()} */}

          <span id="save_page" onClick={onHandleSaveHomePageClick}>
            Save as home page
          </span>
        </div>
      </div>
    </div>
  );
});

export default MenuOptions;
