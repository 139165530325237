import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { APP_THEMES } from "../../constants";
import { IReleaseNote } from "../../constants/interface/admin/releaseNotes";
import {
  BackgroundContext,
  DashboardBannerContext,
  FilterContext,
  PasswordResetContext,
  PopUpMessagesContext,
  ThemeContext,
} from "../../context";
import NewAdminPage from "../../pages/Admin/NewAdminPage";
import { ExperienceInsight, Monitor } from "../../pages/Ariya";
import { HandraisersInsightMarketSplit } from "../../pages/Ariya/Handraisers Insights Market Split/HandraisersInsightMarketSplit";
import { HandraisersInsight } from "../../pages/Ariya/HandraisersInsights/HandraisersInsight";
import {
  PreorderInsights_Belgium,
  PreorderInsights_Canada,
  PreorderInsights_France,
  PreorderInsights_Germany,
  PreorderInsights_Italy,
  PreorderInsights_Japan,
  PreorderInsights_Netherlands,
  PreorderInsights_Norway,
  PreorderInsights_Sweden,
  PreorderInsights_UnitedKingdom,
  PreorderInsights_UnitedStates,
} from "../../pages/Ariya/PreorderInsights/Drilldown";
import { PreorderInsight } from "../../pages/Ariya/PreorderInsights/PreorderInsight";
import BrandPerformanceDashboard from "../../pages/BrandPerformanceDashboard/BrandPerformanceDashboard";
import { Analysis, AppRatings, Cockpit, EuropeActivation, Journey, UAEActivation } from "../../pages/CCS Vital Signs";
import { AppRatingsBreakdown } from "../../pages/CCS Vital Signs/AppReview/AppRatingsBreakdown";
import { TopFlop } from "../../pages/CCS Vital Signs/TopFlop/TopFlop";
import { CedarHome } from "../../pages/Cedar Home/CedarHome";
import CpoOperationalDashboard from "../../pages/CPOOperationalDashboard/CpoOperationalDashboard";
import DfpDashboard from "../../pages/dfp/DfpDashboard";
import Ecommerce from "../../pages/Ecommerce/Ecommerce";
import { AccessDeniedPage, InvalidTokenPage, Page404 } from "../../pages/Error Pages/Login";
import ExecutiveSummaryDis from "../../pages/ExecutiveSummary/Dis/ExecutiveSummaryDis";
import ExecutiveSummaryProductPerformance from "../../pages/ExecutiveSummary/ProductPerformance/ExecutiveSummaryProductPerformance";
import {
  DigitalUserExperience,
  DigitalUserExperienceCoreModels,
  MobilityInsightsReport,
  MobilityReport,
  PurchaseFunnelDiagnostic,
  SearchInterest,
} from "../../pages/Iframes";
import MarketingInvestmentDashboard from "../../pages/Iframes/MarketingInvestmentDashboard";
import { AllReleaseNotes, DashboardInfo, DataInfo } from "../../pages/Info Page";
import JourneyExcellence from "../../pages/Journey Excellence/JourneyExcellence";
import { ForgotPassword, Login, Logout, SetPassword, Welcome } from "../../pages/Login";
import Fixed from "../../pages/Midas/Fixed/Fixed";
import FmiByModel from "../../pages/Midas/FmiByModel/FmiByModel";
import FmiRunRate from "../../pages/Midas/FMIRunRate/FmiRunRate";
import MediaMix from "../../pages/Midas/MediaMix/MediaMix";
import MtpCategory from "../../pages/Midas/MtpCategory/MtpCategory";
import SpendVsTraffic from "../../pages/Midas/SpendVsTrafic/SpendVsTraffic";
import StrategicDealerSupport from "../../pages/Midas/SrategicDealerSupport/StrategicDealerSupport";
import StrategicCE from "../../pages/Midas/StrategicCE/StrategicCE";
import MsrpDashboard from "../../pages/MSRP/MsrpDashboard";
import { MTMReport } from "../../pages/MTM Report/MTMReport";
import { CPODigitalPerformance } from "../../pages/Online CE Performance/CPODigitalPerformance";
import { DigitalPerformance } from "../../pages/Online CE Performance/DigitalPerformance";
import { PowerBiTemplate, PurchaseFunnel } from "../../pages/PowerBiReports";
import ProductMarketingOptimization from "../../pages/ProductMarketingOptimization/ProductMarketingOptimization";
import ProductPerformance from "../../pages/ProductPerformance/ProductPerformance";
import { PurchaseFunnelReport } from "../../pages/Purchase Funnel/PurchaseFunnelReport";
import { SeoDashboard } from "../../pages/SEO/SeoDashboard";
import SignUp from "../../pages/Sign Up/SignUp";
import { OnlineAccountAndEngagement } from "../../pages/UseOwnership/OnlineAccountAndEngagement/OnlineAccountAndEngagement";
import Overview from "../../pages/UseOwnership/Overview/Overview";
import SelfService from "../../pages/UseOwnership/SelfService/SelfService";
import ServiceLead from "../../pages/UseOwnership/ServiceLead/ServiceLead";
import { UserProfile } from "../../pages/UserProfile/UserProfile";
import Website from "../../pages/Voc/Website/Website";
import { ThemeInt } from "../Charts/helpers/types";
import { CcsDataQualityFilters } from "../Filters";
import { SuccessfulPasswordReset } from "../Modals/messages";
import { HomeRedirect } from "./subs/HomeRedirect";
import { PrivateRoute } from "./subs/PrivateRoute";
import QuickVocSales from "../../pages/Voc/QuickVocSales/QuickVocSales";
import QuickVocAfterSales from "../../pages/Voc/QuickVocAfterSales/QuickVocAfterSales";
import QuickVocCec from "../../pages/Voc/QuickVocCec/QuickVocCec";
import VocRsa from "../../pages/Voc/VocRsa/VocRsa";
import GoogleRating from "../../pages/Voc/GoogleRating/GoogleRating";
import CustomerJourneyVoc from "../../pages/Voc/CustomerJourneyVoc/CustomerJourneyVoc";
import Some from "../../pages/Voc/SoMe/Some";

Sentry.init({
  dsn: "https://8cbdbe6119b14a26b94c4f7473e76f6f@o319449.ingest.sentry.io/4504954898022400",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
  tracesSampleRate: 1.0,
});

// Create a client
const queryClient = new QueryClient();

export const App = () => {
  const userTheme = useSelector((state: RootStateOrAny) => state.user.details.dark_mode);

  const [showMobileFilter, setShowMobileFilter] = useState<boolean>(false);
  const [showFilterOptions, setShowFilterOptions] = useState<boolean>(false);
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [bannerHovered, setBannerHovered] = useState<boolean>(false);
  const [region, setRegion] = useState<string>("");

  const [bannerText, setBannerText] = useState<string>("");
  const [detailText, setDetailText] = useState<string>("");

  const [theme, setTheme] = useState<ThemeInt>("dark");
  const [hideBackgroundImage, setHideBackgroundImage] = useState(false);

  const [flashMessage, setFlashMessage] = useState("");
  const [showFlashMessage, setShowFlashMessage] = useState(false);

  const [releaseNote, setReleaseNote] = useState<IReleaseNote | undefined>();
  const [showReleaseMessage, setShowReleaseMessage] = useState(false);
  const [releaseVersion, setReleaseVersion] = useState("");

  const [showResetModal, setShowResetModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [token, setUserToken] = useState("");

  // passes meta data to chatlio.
  // useEffect(() => {
  //   const id = Cookies.get("id");
  //   const email = Cookies.get("email");
  //   const name = Cookies.get("display_name");
  //   const role = Cookies.get("role");
  //   const business_function = Cookies.get("business_function");
  //   if (typeof window !== "undefined" && id) {
  //     // @ts-ignore
  //     window._chatlio.identify(id, {
  //       name: name,
  //       email: email,
  //       role: role,
  //       business_function: business_function,
  //     });
  //   }
  // }, []);

  // passes meta data to social intents.
  useEffect(() => {
    const id = Cookies.get("id");
    const email = Cookies.get("email");
    const name = Cookies.get("display_name");
    const role = Cookies.get("role");
    const business_function = Cookies.get("business_function");
    if (typeof window !== "undefined" && id) {
      // @ts-ignore
      window.SI_API && window.SI_API.setChatInfo(name, email, '', role);
    }
  }, []);

  useEffect(() => {
    const track = Cookies.get("on_ga");
    if (typeof window !== "undefined" && !track) {
      // @ts-ignore
      window["ga-disable-GA_MEASUREMENT_ID"] = true;
    }
  }, []);

  useEffect(() => {
    userTheme ? setTheme("dark") : setTheme("light");
  }, [userTheme]);

  // Show set password modal, when new_user cookie exists and is True
  useEffect(() => {
    const newUserCookie = Cookies.get("new_user");

    if (newUserCookie && /true/gi.test(newUserCookie)) {
      setShowResetModal(true);
    }
  }, []);

  const referrer = window.location.pathname;
  const searchString = window.location.search;

  // Updates global theme
  useEffect(() => {
    if (theme) {
      const Html: HTMLHtmlElement | null = document.querySelector("html");
      // Removes all themes set
      Html && APP_THEMES.forEach((themeClass) => Html.classList.remove(themeClass));

      Html && Html.classList.add(`theme-${theme}`);
    }
  }, [theme]);

  return (
    <DashboardBannerContext.Provider
      value={{
        setShowBanner,
        setDetailText,
        detailText,
        bannerText,
        setBannerText,
        showBanner,
        bannerHovered,
        setBannerHovered,
        region,
        setRegion,
      }}
    >
      <BackgroundContext.Provider value={{ hideBackgroundImage, setHideBackgroundImage }}>
        <ThemeContext.Provider value={{ theme, setTheme }}>
          <PopUpMessagesContext.Provider
            value={{
              flashMessage,
              setFlashMessage,
              showFlashMessage,
              setShowFlashMessage,
              releaseNote,
              setReleaseNote,
              showReleaseMessage,
              setShowReleaseMessage,
            }}
          >
            <FilterContext.Provider
              value={{
                showMobileFilter,
                setShowMobileFilter,
                showFilterOptions,
                setShowFilterOptions,
              }}
            >
              <PasswordResetContext.Provider
                value={{
                  showSuccessMessage,
                  setShowSuccessMessage,
                  showResetModal,
                  setShowResetModal,
                  token,
                  setUserToken,
                }}
              >
                <SuccessfulPasswordReset />
                <QueryClientProvider client={queryClient}>
                  <Router>
                    <Switch>
                      <Route exact path="/login" component={Login} />
                      <Route exact path="/logout" component={Logout} />
                      <Route exact path="/sign_up" component={SignUp} />
                      <Route exact path="/forgotten_password" component={ForgotPassword} />
                      <Route exact path="/set-password" component={SetPassword} />
                      <Route exact path="/welcome" component={Welcome} />

                      {/* Error Routes */}
                      <Route exact path="/403" component={AccessDeniedPage} />
                      <Route exact path="/404" component={Page404} />
                      <Route exact path="/invalid_token" component={InvalidTokenPage} />

                      {/* Authenticated routes */}
                      <PrivateRoute exact path="/home" render={() => <CedarHome />} />
                      <PrivateRoute exact path="/user_profile" render={() => <UserProfile />} />
                      <PrivateRoute exact path="/digital_performance" render={() => <DigitalPerformance />} />
                      <PrivateRoute exact path="/cpo_digital_performance" render={() => <CPODigitalPerformance />} />
                      <PrivateRoute exact path="/data_info" render={() => <DataInfo />} />
                      <PrivateRoute exact path="/dashboard_info" render={() => <DashboardInfo />} />
                      <PrivateRoute exact path="/ccs_vital_signs/ccs_cockpit" render={() => <Cockpit />} />
                      <PrivateRoute exact path="/ccs_vital_signs/app_ratings" render={() => <AppRatings />} />
                      <PrivateRoute exact path="/ccs_vital_signs/app_ratings_breakdown" render={() => <AppRatingsBreakdown />} />
                      <PrivateRoute
                        exact
                        path="/ccs_vital_signs/data_quality"
                        render={() => (
                          <PowerBiTemplate
                            id="ccs_data_quality_report"
                            tokenPathKey="data_quality"
                            filter={<CcsDataQualityFilters />}
                            showReportPagePane={false}
                            pageTracking={{ pathname: "/ccs_vital_signs/data_quality", searchString: "" }}
                            multiPageTracking={{ mainPageName: "New CCS TRACKING", pageName: "Data quality" }}
                          />
                        )}
                      />
                      <PrivateRoute exact path="/ccs_vital_signs/ccs_journey" render={() => <Journey />} />
                      <PrivateRoute exact path="/ccs_vital_signs/ccs_journey/europe_activation" render={() => <EuropeActivation />} />
                      <PrivateRoute exact path="/ccs_vital_signs/ccs_journey/uae_activation" render={() => <UAEActivation />} />
                      <PrivateRoute exact path="/ccs_vital_signs/top_flop" render={() => <TopFlop />} />
                      <PrivateRoute exact path="/ccs_vital_signs/ccs_analysis" render={() => <Analysis />} />
                      <PrivateRoute exact path="/purchase_funnel_report" render={() => <PurchaseFunnelReport />} />
                      <PrivateRoute exact path="/purchase_funnel_report/msrp" render={() => <MsrpDashboard />} />
                      <PrivateRoute exact path="/product_marketing_optimization" render={() => <ProductMarketingOptimization />} />
                      <PrivateRoute exact path="/product_marketing_optimization/dfp_dashboard" render={() => <DfpDashboard />} />
                      <PrivateRoute exact path="/brand_performance" render={() => <BrandPerformanceDashboard />} />

                      {/**INFO */}
                      <PrivateRoute exact path="/data_info" render={() => <DataInfo />} />
                      <PrivateRoute exact path="/dashboard_info" render={() => <DashboardInfo />} />
                      <PrivateRoute exact path="/release_notes" render={() => <AllReleaseNotes />} />

                      {/**IFRAMES */}
                      <PrivateRoute exact path="/purchase_funnel" render={() => <PurchaseFunnel />} />
                      <PrivateRoute exact path="/purchase_funnel/diagnostic" render={() => <PurchaseFunnelDiagnostic />} />
                      <PrivateRoute exact path="/mobility_report" render={() => <MobilityReport />} />
                      <PrivateRoute exact path="/mobility_report/insights" render={() => <MobilityInsightsReport />} />
                      <PrivateRoute
                        exact
                        path="/online_to_physical"
                        render={() => (
                          <PowerBiTemplate
                            id="online_to_physical"
                            tokenPathKey="online_to_physical"
                            showReportPagePane={true}
                            pageTracking={{ pathname: "/online_to_physical", searchString: "" }}
                          />
                        )}
                      />
                      <PrivateRoute exact path="/mtm_metrics" render={() => <MTMReport />} />
                      <PrivateRoute exact path="/search_interest" render={() => <SearchInterest />} />
                      <PrivateRoute exact path="/digital_user_experience" render={() => <DigitalUserExperience />} />
                      <PrivateRoute exact path="/digital_user_experience_core_models" render={() => <DigitalUserExperienceCoreModels />} />
                      <PrivateRoute
                        exact
                        path="/beta/car_configurator"
                        render={() => (
                          <PowerBiTemplate
                            id="new_beta_car_configurator"
                            tokenPathKey="new_beta_car_configurator"
                            showReportPagePane={false}
                            pageTracking={{ pathname: "/beta/car_configurator", searchString: "" }}
                          />
                        )}
                      />
                      <PrivateRoute
                        exact
                        path="/beta/configurator-g5"
                        render={() => (
                          <PowerBiTemplate
                            id="configurator_g5"
                            tokenPathKey="configurator_g5"
                            showReportPagePane={false}
                            pageTracking={{ pathname: "/beta/configurator-g5", searchString: "" }}
                          />
                        )}
                      />
                      <PrivateRoute
                        exact
                        path="/beta/configurator-nibu"
                        render={() => (
                          <PowerBiTemplate
                            id="configurator_nibu"
                            tokenPathKey="configurator_nibu"
                            showReportPagePane={false}
                            pageTracking={{ pathname: "/beta/configurator-nibu", searchString: "" }}
                          />
                        )}
                      />

                      {/* Admin page */}
                      <PrivateRoute exact path="/admin" render={() => <NewAdminPage />} />

                      {/* Ariya */}
                      <Redirect from="/worldpremiere" to={"/ariya/monitor"} />
                      <Redirect from="/ariya/monitor.html" to={"/ariya/monitor"} />
                      <PrivateRoute exact path="/ariya/monitor" render={() => <Monitor />} />
                      <PrivateRoute exact path="/ariya/experience" render={() => <ExperienceInsight />} />
                      <PrivateRoute exact path="/ariya/handraisers/market-split" render={() => <HandraisersInsightMarketSplit />} />
                      <PrivateRoute exact path="/ariya/handraisers" render={() => <HandraisersInsight />} />
                      <PrivateRoute exact path="/ariya/preorder/japan" render={() => <PreorderInsights_Japan />} />
                      <PrivateRoute exact path="/ariya/preorder/norway" render={() => <PreorderInsights_Norway />} />
                      <PrivateRoute exact path="/ariya/preorder/united-states" render={() => <PreorderInsights_UnitedStates />} />
                      <PrivateRoute exact path="/ariya/preorder/united-kingdom" render={() => <PreorderInsights_UnitedKingdom />} />
                      <PrivateRoute exact path="/ariya/preorder/netherlands" render={() => <PreorderInsights_Netherlands />} />
                      <PrivateRoute exact path="/ariya/preorder/canada" render={() => <PreorderInsights_Canada />} />
                      <PrivateRoute exact path="/ariya/preorder/france" render={() => <PreorderInsights_France />} />
                      <PrivateRoute exact path="/ariya/preorder/germany" render={() => <PreorderInsights_Germany />} />
                      <PrivateRoute exact path="/ariya/preorder/italy" render={() => <PreorderInsights_Italy />} />
                      <PrivateRoute exact path="/ariya/preorder/sweden" render={() => <PreorderInsights_Sweden />} />
                      <PrivateRoute exact path="/ariya/preorder/belgium" render={() => <PreorderInsights_Belgium />} />
                      <PrivateRoute exact path="/ariya/preorder" render={() => <PreorderInsight />} />

                      {/* SEO page */}
                      <PrivateRoute exact path="/seo_dashboard" render={() => <SeoDashboard />} />

                      {/* MIDAS page */}
                      <PrivateRoute exact path="/midas" render={() => <MarketingInvestmentDashboard />} />
                      <PrivateRoute exact path="/midas/fmi_run_rate" render={() => <FmiRunRate />} />
                      <PrivateRoute exact path="/midas/fmi_by_model" render={() => <FmiByModel />} />
                      <PrivateRoute exact path="/midas/media_mix" render={() => <MediaMix />} />
                      <PrivateRoute exact path="/midas/mtp_category" render={() => <MtpCategory />} />
                      <PrivateRoute exact path="/midas/fixed" render={() => <Fixed />} />
                      <PrivateRoute exact path="/midas/strategic_ce" render={() => <StrategicCE />} />
                      <PrivateRoute exact path="/midas/strategic_dealer_support" render={() => <StrategicDealerSupport />} />
                      <PrivateRoute exact path="/midas/spend_vs_traffic" render={() => <SpendVsTraffic />} />

                      {/* CPO Operational Dashboard */}
                      <PrivateRoute exact path="/cpo_operational" render={() => <CpoOperationalDashboard />} />
                      <PrivateRoute exact path="/product_performance" render={() => <ProductPerformance />} />

                      {/* VOC page */}
                      <PrivateRoute exact path="/voc/website" render={() => <Website />} />
                      <PrivateRoute exact path="/voc/quick_voc_sales" render={() => <QuickVocSales />} />
                      <PrivateRoute exact path="/voc/quick_voc_after_sales" render={() => <QuickVocAfterSales />} />
                      <PrivateRoute exact path="/voc/cec_voc" render={() => <QuickVocCec />} />
                      <PrivateRoute exact path="/voc/voc_rsa" render={() => <VocRsa />} />
                      <PrivateRoute exact path="/voc/google_rating" render={() => <GoogleRating />} />
                      <PrivateRoute exact path="/voc/customer_journey_voc" render={() => <CustomerJourneyVoc />} />
                      <PrivateRoute exact path="/voc/some" render={() => <Some />} />

                      {/* Ecommerce page */}
                      <PrivateRoute exact path="/ecommerce" render={() => <Ecommerce />} />

                      {/* Journey Excellence page */}
                      <PrivateRoute exact path="/journey_excellence" render={() => <JourneyExcellence />} />

                      {/* Use Ownership page */}
                      <PrivateRoute exact path="/use_ownership/overview" render={() => <Overview />} />
                      <PrivateRoute
                        exact
                        path="/use_ownership/online_account_and_engagement"
                        render={() => <OnlineAccountAndEngagement />}
                      />
                      <PrivateRoute exact path="/use_ownership/service_lead" render={() => <ServiceLead />} />
                      <PrivateRoute exact path="/use_ownership/self_service" render={() => <SelfService />} />

                      {/* Executive Summary page */}
                      <PrivateRoute exact path="/executive_summary/dis" render={() => <ExecutiveSummaryDis />} />
                      <PrivateRoute
                        exact
                        path="/executive_summary/product_performance"
                        render={() => <ExecutiveSummaryProductPerformance />}
                      />

                      <Redirect
                        from="/digital_performance/:version"
                        to={{
                          pathname: "/digital_performance",
                          search: searchString,
                          state: { referrer },
                        }}
                      />

                      {/* Redirects old beta link to fruits section */}
                      <Redirect
                        exact
                        from="/new_mtm_report"
                        to={{
                          pathname: "/mtm_metrics",
                          search: searchString,
                          state: { referrer },
                        }}
                      />

                      {/* Redirects invalid ccs page to the cockpit view */}
                      <Redirect
                        from="/ccs_journey/europe_activation"
                        to={{
                          pathname: "/ccs_vital_signs/ccs_journey/europe_activation",
                          search: searchString,
                          state: { referrer },
                        }}
                      />

                      <Redirect
                        from="/ccs_journey/uae_activation"
                        to={{
                          pathname: "/ccs_vital_signs/ccs_journey/uae_activation",
                          search: searchString,
                          state: { referrer },
                        }}
                      />

                      {/* Redirects invalid ccs page to the cockpit view */}
                      <Redirect
                        from="/ccs_vital_signs/:page"
                        to={{
                          pathname: "/ccs_vital_signs/ccs_cockpit",
                          search: searchString,
                          state: { referrer },
                        }}
                      />

                      {/* Redirects ccs navigation with no page to the cockpit page */}
                      <Redirect
                        from="/ccs_vital_signs"
                        to={{
                          pathname: "/ccs_vital_signs/ccs_cockpit",
                          search: searchString,
                          state: { referrer },
                        }}
                      />

                      {/* Redirects worldpremiere to ariya*/}
                      <Redirect
                        from="/worldpremiere"
                        to={{
                          pathname: "/ariya/monitor.html",
                          search: searchString,
                          state: { referrer },
                        }}
                      />
                      <Redirect
                        from="/ariya"
                        to={{
                          pathname: "/ariya/monitor.html",
                          search: searchString,
                          state: { referrer },
                        }}
                      />

                      {/* Redirect if no path is selected */}
                      <Route path={"/"} component={HomeRedirect} />

                      {/* Todo: Add redirect on missing  path */}
                    </Switch>
                  </Router>
                </QueryClientProvider>
              </PasswordResetContext.Provider>
            </FilterContext.Provider>
          </PopUpMessagesContext.Provider>
        </ThemeContext.Provider>
      </BackgroundContext.Provider>
    </DashboardBannerContext.Provider>
  );
};
