import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchVocFilters, setParameters, setSpecificParameter } from "../../../actions";
import "../../../assets/styles/component/filters.scss";
import { DEFAULT_VOC_PARAMETERS, MIN_SELECTABLE_DATE, PMO_DATE_FILTER_OPTIONS, VOC_DATE_FILTER_OPTIONS } from "../../../constants";
import { FilterContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../../utils/utilityFunctions";
import { ErrorMsg } from "../../AppMessages";
import { FiltersLayout } from "../../Layouts";
import { DateFilter, DefaultFilter, MultiSelectFilter } from "../common";
import { closeFilterOptions, closeMarketOptions } from "../subs/helpers";
import { Button } from "@material-ui/core";

interface Props {
  filterBtns: Array<{ id: string; navigateTo: string; name: string }>;
  inComplete?: boolean;
}

const VocWebsiteFilters = withRouter((props: RouteComponentProps & Props) => {
  const { filterBtns, history, inComplete } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.voc_last_data_refresh);
  const { brand, market, objective } = useSelector((state: RootStateOrAny) => state.filters.voc_filters);

  const {
    date_range: dateRangeParamValue,
    brand: brandParamValue,
    market: marketParamValue,
    objective: objectiveParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const { pageName } = useSelector((state: RootStateOrAny) => state.page);

  const [marketFilterValue, setMarketFilterValue] = useState<string>("");
  const [objectivesFilterValue, setObjectiveFilterValue] = useState<string>("");
  const [brandParam, setBrandParam] = useState<string>(brandParamValue);
  const [marketParam, setMarketParam] = useState<string>(marketParamValue);
  const [defaultObjectiveSelected, setDefaultObjectiveSelected] = useState<boolean>(false);

  //Sets the initial start and end selectable date to be 1st April 2019 and the latest data refresh | today() - 1 (yesterday) if the last data refresh is not yet defined
  const [marketDataAvailability, setMarketDataAvailability] = useState<{ start: string; end: string }>({
    start: MIN_SELECTABLE_DATE,
    end: lastDataRefresh || moment().subtract(1, "days").format("DD/MM/YYYY"),
  });

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("VOC - Website");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_VOC_PARAMETERS));
      currentDefaultFiltersSet("VOC - Website");
    }
  }, []);

  useEffect(() => {
    dispatch(fetchVocFilters());
    if (!PMO_DATE_FILTER_OPTIONS.includes(dateRangeParamValue)) {
      dispatch(setSpecificParameter("date_range", PMO_DATE_FILTER_OPTIONS[0]));
    }
  }, []);

  useEffect(() => {
    if (marketParamValue == "All countries") {
      dispatch(setSpecificParameter("market", "All"));
    }
  }, [marketParamValue]);

  // Sets market value
  useEffect(() => {
    const selectedMarkets =
      market?.length === marketParamValue.split(",")?.length
        ? "All"
        : marketParamValue.includes(",")
        ? `${marketParamValue.split(",")?.length} markets selected`
        : marketParamValue;
    setMarketParam(marketParamValue);
    setMarketFilterValue(selectedMarkets);
  }, [marketParamValue, market]);

  useEffect(() => {
    if (objective?.length && objectiveParamValue) {
      const selectedObjectives =
        objective?.length === objectiveParamValue.split(",")?.length
          ? "All"
          : objectiveParamValue.includes(",")
          ? `${objectiveParamValue.split(",")?.length} objectives selected`
          : objectiveParamValue;
      setObjectiveFilterValue(selectedObjectives);
    }
  }, [objectiveParamValue, objective]);

  //set brand value
  useEffect(() => {
    setBrandParam(brandParamValue);
  }, [brandParamValue]);

  useEffect(() => {
    if (objectiveParamValue && objective) {
      const selectedObjective = objectiveParamValue?.split(",");
      const objectivessAreValid = selectedObjective?.every((market: string) => [...objective].includes(market));
      !objectivessAreValid && dispatch(setSpecificParameter("objective", "All"));
    }
  }, [objectiveParamValue, objective, defaultObjectiveSelected]);

  useEffect(() => {
    if (objective && !defaultObjectiveSelected) {
      dispatch(setSpecificParameter("objective", objective[0]));
      setDefaultObjectiveSelected(true);
    }
  }, [objective, defaultObjectiveSelected]);

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));
      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: "VOC",
        page: "Website",
      });
    }
    closeFilterOptions();
    setShowFilterOptions(false);
  };

  const handleMarketFilterSubmission = useCallback(() => {
    const selectedMarketCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="market"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;
    const selectedMarkets: Array<string> = [];
    selectedMarketCheckboxes?.forEach((checkbox) => selectedMarkets.push(checkbox.value));

    closeFilterOptions();
    const value = market?.length === selectedMarkets?.length ? "All" : selectedMarkets.join(",");
    dispatch(setSpecificParameter("market", value));

    eventTracking(MixpanelEvents.filter_change, { filter: "market", value, dashboard: "VOC" });
  }, [market]);

  const handleObjectiveFilterSubmission = useCallback(() => {
    const selectedObjectiveCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="objective"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;
    const selectedObjectives: Array<string> = [];
    selectedObjectiveCheckboxes?.forEach((checkbox) => selectedObjectives.push(checkbox.value));

    closeFilterOptions();
    const value = objective?.length === selectedObjectives?.length ? "All" : selectedObjectives.join(",");
    dispatch(setSpecificParameter("objective", value));

    eventTracking(MixpanelEvents.filter_change, { filter: "objectives", value, dashboard: "VOC", page: "Website" });
  }, [objective]);

  const resetFilters = useCallback(() => {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(DEFAULT_VOC_PARAMETERS)) {
      params.set(key, value);
    }

    history.push({ search: `?${params.toString()}` });
    window.location.reload();
  }, [history]);

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateRangeParamValue);
    params.set("brand", brandParam);
    params.set("market", marketParam);
    params.set("objective", objectiveParamValue);
    history.push({ search: `?${params.toString()}` });
  }, [dateRangeParamValue, brandParam, marketParam, objectiveParamValue]);

  const onRenderOptions = ({ list, filter }: { list: string[]; filter: string }) => {
    return (
      <>
        {list?.map((item: string) => {
          return (
            <li key={item} data-filter={filter} onClick={handleFilterOptionClick} data-value={item}>
              {item}
            </li>
          );
        })}
      </>
    );
  };

  const renderDashboardClassificationButtons = () => {
    return (
      <>
        <Button variant={"outlined"} className={`filter_btn_element active`} size={"small"} disabled>
          Try
        </Button>
        <Button variant={"outlined"} className={`filter_btn_element`} size={"small"} disabled>
          Buy
        </Button>
        <Button variant={"outlined"} className={`filter_btn_element`} size={"small"} disabled>
          Use
        </Button>
      </>
    );
  };

  return (
    <FiltersLayout
      extraClass={"iframeFilters-midas"}
      resetFilters={resetFilters}
      filterBtns={filterBtns}
      renderDashboardClassificationButtons={pageName == "VOC (Voice Of Customer)" ? renderDashboardClassificationButtons : undefined}
    >
      <>
        {inComplete ? null : (
          <div className="filte">
            {/* Date range */}
            <ErrorBoundary fallback={<ErrorMsg />}>
              <DateFilter
                onFilterClick={showFilterOptionsFn}
                renderOptions={onRenderOptions}
                value={dateRangeParamValue}
                maxDate={lastDataRefresh}
                availableDates={marketDataAvailability}
                dateOptions={VOC_DATE_FILTER_OPTIONS}
                page="VOC"
              />
            </ErrorBoundary>

            <ErrorBoundary fallback={<ErrorMsg />}>
              <DefaultFilter
                list={brand}
                filterName={"brand"}
                filterLabel={"BRAND"}
                filterValue={brandParamValue}
                handleFilterOptionClick={handleFilterOptionClick}
              />
            </ErrorBoundary>
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MultiSelectFilter
                parentKey="market"
                // @ts-ignore
                filterList={market ? market : []}
                filterName="MARKET"
                value={marketFilterValue}
                parameterName={"market"}
                parameterValue={marketParamValue}
                onShowFilterOptions={showFilterOptionsFn}
                handleFilterSubmission={handleMarketFilterSubmission}
              />
            </ErrorBoundary>
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MultiSelectFilter
                parentKey="objective"
                // @ts-ignore
                filterList={objective ? objective : []}
                filterName="OBJECTIVES"
                value={objectivesFilterValue}
                parameterName={"objective"}
                parameterValue={objectiveParamValue}
                onShowFilterOptions={showFilterOptionsFn}
                handleFilterSubmission={handleObjectiveFilterSubmission}
              />
            </ErrorBoundary>
          </div>
        )}
      </>
    </FiltersLayout>
  );
});

export default VocWebsiteFilters;
