import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageName } from "../../../actions";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { VOC_BTN_FILTERS } from "../../../constants/vocConstants";
import { PageTitle } from "../../../components/PageContent/subs";
import VocQuickVocCecFilters from "../../../components/Filters/Voc/VocQuickVocCecFilters";
import {
  getQuickVocCecFilters,
  getQuickVocCecLastRefreshDate,
  getQuickVocCecMarketDeepDiveTable,
  getQuickVocCecMarketDeepDiveTrendline,
  getQuickVocCecPerformanceByMarket,
} from "../../../api/quickVocCecApi";
import { useQuery } from "@tanstack/react-query";
import Summary from "./components/Summary";
import "../../../assets/styles/pages/quickVocCec.scss";
import PerformanceByMarket from "../QuickVocSales/components/PerformanceByMarket";
import MarketDeepDive from "../QuickVocSales/components/MarketDeepDive";
import { DashboardBannerContext } from "../../../context";

const metricMapping: Record<string, string> = {
  "Scores Complaint": "complaint",
  "Scores Info": "info",
  "Scores CCS": "ccs",
};

const QuickVocCec = () => {
  const dispatch = useDispatch();

  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);

  useEffect(() => {
    setShowBanner(true);
    setBannerText(
      "Please note that figures presented in the dashboard are a sample of FY24 data. CEDAR and AMIEO are collaborating to make full figures and extended date ranges available shortly."
    );
    return () => {
      setShowBanner(false);
      setBannerText("");
    };
  }, []);

  const { data: lastDataRefreshData, refetch: refetchLastDataRefresh } = useQuery({
    queryKey: ["quickVocCecLastDataRefresh"],
    queryFn: getQuickVocCecLastRefreshDate,
    enabled: false,
  });

  useEffect(() => {
    dispatch(setPageName("CEC VOC"));
    refetchLastDataRefresh();
  }, [dispatch]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<VocQuickVocCecFilters filterBtns={VOC_BTN_FILTERS} queryFn={getQuickVocCecFilters} pageName="CEC VOC" />}
        dataDocumentation="voc"
        lastDataRefresh={lastDataRefreshData?.date ?? ""}
        pageTitle={<PageTitle dataDocumentation="voc" />}
      >
        <div className="quick-voc-cec">
          <Summary />
          <PerformanceByMarket
            queryFn={getQuickVocCecPerformanceByMarket}
            metrics={["Scores Complaint", "Scores Info", "Scores CCS"]}
            metricMapping={metricMapping}
          />
          <MarketDeepDive tableQueryFn={getQuickVocCecMarketDeepDiveTable} trendlineQueryFn={getQuickVocCecMarketDeepDiveTrendline} />
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
};

export default QuickVocCec;
